<template>
  <div class=" primary w-100 h-100">
    <v-card class="w-xs-100" flat color="primary center">
      <v-card-title class="d-flex justify-center text-bold text-h4">
        <v-img class="flex-none mr-4" width="48" height="48" src="@/assets/images/logo-white.svg" />

        TICKET TEXT
      </v-card-title>

      <v-card-title class="justify-center my-4 text-subtitle-1 text-sm-h6">
        Great to have you on board {{ $user.firstName }}!
      </v-card-title>

      <v-card-subtitle v-if="$user" class="text-center white--text text-subtitle-2 text-sm-subtitle-1">
        <span v-if="['admin', 'promoter'].includes($user.role)">
          You would be redirected to the Dashboard in a second!
          <br />
          Please refresh/login again if it is taking time.
        </span>
        <span v-else>
          You would be redirected to Home page in a second!
          <br />
          Please refresh/login again if it is taking time.
        </span>
      </v-card-subtitle>
    </v-card>
  </div>
</template>

<script>
export default {
  mounted() {
    setTimeout(() => {
      if (localStorage.getItem('isEventPageSignup')) {
        this.$next({  name: 'visitor.event', params: { id: localStorage.getItem('isEventPageSignup') } } );
      } else if (this.$user.role === 'admin') {
        this.$next({ name: 'admin.dashboard' });
      } else if (this.$user.role === 'promoter') {
        this.$next({ name: 'promoter.dashboard' });
      } else if (this.$user.role === 'venue') {
        this.$next({ name: 'venue.events' });
      } else {
        this.$next({ name: 'visitor.dashboard' });
      }
    }, 5000);
  },
};
</script>
